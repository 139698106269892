@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  font-family: "Montserrat", sans-serif;
  color: dark;
}

@layer utilities {
  .text-gradient {
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.blurred-background {
  filter: blur(5px);
  transition: filter 0.3s;
}

.no-blur {
  filter: none;
  transition: filter 0.3s;
}
